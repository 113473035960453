import { createActionGroup, createFeature, createReducer, createSelector, on, props } from '@ngrx/store';

import { environment } from '../../environments/environment';
import { BaseConfigValueFragment, ZoneBranding } from '../../generated/graphql.generated';
import { defaultCountry, jurisdictions } from '../global.constants';
import { ConfigKey, ConfigValue, formatConfigValue } from '../services/frontend-configs';


export const BrandingActions = createActionGroup({
	source: 'Branding',
	events: {
		'Branding Loading': props<{
			loading: boolean;
		}>(),
		'Branding Load Error': props<{
			error: Error;
		}>(),
		'Branding Updated': props<{
			branding: ZoneBranding,
			features: string[],
			configValues: BaseConfigValueFragment[],
		}>(),
	},
});

const brandingInitialState = {
	branding: undefined as ZoneBranding,
	configs: {} as { [ key in ConfigKey ]: ConfigValue<key> },
	country: defaultCountry,

	currency: defaultCountry.currency,
	units: defaultCountry.units,

	loading: true as boolean,
};

export type BrandingState = typeof brandingInitialState;

export const brandingFeature = createFeature({
	name: 'Branding',
	reducer: createReducer(
		brandingInitialState,
		on(BrandingActions.brandingUpdated, (state, { branding, configValues }): BrandingState => {

			const configs = {} as typeof state.configs;

			// configValues may be null if we are not authenticated
			for (const config of configValues || []) {
				configs[config.key] = formatConfigValue(config);
			}

			const countryCode = branding.country || environment.defaultCountry;

			let country = jurisdictions.find((j) => j.country === countryCode);
			if (!country) {
			  country = jurisdictions.find((j) => j.country === environment.defaultCountry);
			}

			const currency = country.currency;
			const units = country.units;

			return {
				...state,
				branding,
				configs,
				currency,
				units,
				loading: false,
			};
		}),
		on(BrandingActions.brandingLoading, (state, { loading }): BrandingState => {
			return {
				...state,
				loading,
			}
		}),
	),
});

export function createConfigSelector<T extends ConfigKey, R = any>(configKey: T) {
	return createSelector(brandingFeature.selectConfigs, (configs) => {
		return configs[configKey];
	});
}

export const selectSystemDetails = createSelector(brandingFeature.selectBranding, (branding) => {
	if (!branding) { return; }
	return {
		timezone: branding.timezone,
		country: branding.country,
		language: branding.language,
	}
});
