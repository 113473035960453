import { Component, Input, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';

import { FreyaCommonModule } from 'src/app/freya-common/freya-common.module';
import { LibModule } from 'src/app/lib.ts/lib.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { EstimatesJobFragment, Price, Product } from 'src/generated/graphql.generated';
import { SubSink } from 'subsink';

import { WorkOrdersActions } from '../../job-state/workorders-state/workorders.actions';
import { workOrdersSelectors } from '../../job-state/workorders-state/workorders.selectors';
import { jobToolFeature } from '../../job-tool.reducer';
import { getDisabledStatus, handlePercentageQuantity } from '../../jobsv2-charges-helpers';
import { CalendarEventWithLockedAndInvoicedFlag, ChargeWithQuantity, ChargesUpdate } from '../../jobv2-create/jobv2-interfaces';
import { MutateChargesV2Component } from '../../mutate-charges-v2/mutate-charges-v2.component';


@Component({
    selector: 'app-add-charges',
    standalone: true,
    imports: [
        FreyaCommonModule,
        SharedModule,
        LibModule,
        MutateChargesV2Component,
    ],
    templateUrl: './add-charges.component.html',
    styleUrls: ['./add-charges.component.scss']
})


export class AddChargesComponent implements OnInit, OnDestroy {
    @Input() event: CalendarEventWithLockedAndInvoicedFlag;
    @ViewChild('mutate') mutateRef: MutateChargesV2Component;

    job: EstimatesJobFragment;

    productsLoading = false;
    availableProducts: ChargeWithQuantity[] = [];
    productSearch = '';

    chargesUpdates: ChargesUpdate[] = [];
    addedFixedChargesTotal = 0;
    addedPercentageCharges = false;
    isChargesToAdd = false;
    warningMessage = '';
    showAvailableProductsDialog = false;
    jobLoading = false;

    //observables
    job$ = this.store.select(jobToolFeature.selectJob);
    jobLoading$ = this.store.select(jobToolFeature.isJobLoading);
    availableProducts$ = this.store.select(workOrdersSelectors.selectProductsSelectedForAdding);
    productsLoading$ = this.store.select(jobToolFeature.productsLoading);
    chargesUpdates$ = this.store.select(jobToolFeature.selectChargesUpdates);


    constructor(
        private store: Store,
    ) { }


    private subs = new SubSink();
    public getDisabledStatus = getDisabledStatus;


    ngOnInit(): void {
        this.subs.sink = this.job$.subscribe(job => {
            if (job) {
                this.job = cloneDeep(job);
            }
        });


        this.subs.sink = this.availableProducts$.subscribe(availableProducts => {
            if (availableProducts) {
                this.availableProducts = cloneDeep(availableProducts);
            }
        });


        this.subs.sink = this.productsLoading$.subscribe(productsLoading => {
            this.productsLoading = productsLoading;
        });

        this.subs.sink = this.jobLoading$.subscribe((jobLoading) => {
            this.jobLoading = cloneDeep(jobLoading);
        });

        this.subs.sink = this.chargesUpdates$.subscribe(chargesUpdates => {
            this.chargesUpdates = cloneDeep(chargesUpdates);
            this.isChargesToAdd = this.chargesUpdates.some(charge => charge.quantity);
        });
    }


    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }


    openAvailableProductsModal() {
        this.showAvailableProductsDialog = true;
    }


    handleInputClick(event: any) {
        event.stopPropagation();
        if (typeof event?.target?.select === 'function') {
            event.target.select();
        }
    }


    addChargesToCurrentEvent() {
        if (this.isChargesToAdd) {
            this.store.dispatch(WorkOrdersActions.productsForAddingSubmitted());
            this.showAvailableProductsDialog = false;
        }
    }


    cancelCharges() {
        this.store.dispatch(WorkOrdersActions.productForAddingCancelled());
        this.showAvailableProductsDialog = false;
    }


    /*generateWarning() {
        if (this.addedPercentageCharges) {
            this.warningMessage = 'Percentage based charges will be applied to all event charges';
        } else if (!this.isChargesToAdd) {
            this.warningMessage = 'Select charges above to add charges';
        } else {
            this.warningMessage = '';
        }
    }*/


    handleQuantityChange(product: Product, activePrice: Price, quantity: number | boolean) {
        const unifiedQuantity = handlePercentageQuantity(quantity);

        this.store.dispatch(WorkOrdersActions.productForAddingSelected({
            productId: product?.id,
            priceId: activePrice?.id,
            quantity: unifiedQuantity,
            eventId: this.event?.id,
        }))
    }


    handleClickOnProductRow(product: Product) {
        /*const activePrice = this.productHelper.getActivePrice(product.prices, this.job?.zone?.id);
        if (activePrice.priceType === 'percentage') {
            return;
        }


        if (this.availableProducts.length) {
            const existingChargeIndex = this.availableProducts.findIndex(charge => charge.id === product.id);


            if (this.availableProducts[existingChargeIndex].quantity) {
                this.availableProducts[existingChargeIndex].quantity += 1;
            } else {
                this.availableProducts[existingChargeIndex].quantity = 1;
            }


            this.isChargesToAdd = true;
        }


        //this.calculateAddedTotal();
        //this.generateWarning();*/
    }


    /*calculateAddedTotal() {
        if (this.availableProducts.length) {
            const activePricesFixed = this.availableProducts
                .filter(charge => (charge.quantity && this.productHelper.getActivePrice(
                    charge.prices as Price[], this.job?.zone?.id).priceType === 'fixed'))
                .map(charge => {
                    const activePrice = this.productHelper.getActivePrice(charge.prices as Price[], this.job?.zone?.id);
                    return { amount: activePrice.amount, quantity: charge.quantity };
                });
            this.addedFixedChargesTotal = activePricesFixed.reduce((total, price) => total + (price.amount * price.quantity), 0);


            this.addedPercentageCharges = this.availableProducts
                .some(charge => (charge.quantity
                    && this.productHelper.getActivePrice(charge.prices as Price[], this.job?.zone?.id).priceType === 'percentage'));
        }
        if (!this.availableProducts.some(charge => charge.quantity)) {
            this.addedFixedChargesTotal = 0;
            this.addedPercentageCharges = false;
        }
    }*/


    createCustomCharge() {
        this.showAvailableProductsDialog = false;
        this.mutateRef.mutateType = 'create';
        this.mutateRef.openDialog();
    }


    productTrackBy(index: number, item: ChargeWithQuantity) {
      return item.id;
    }
}
